var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HeaderStyled, LogoStyled } from "./styles";
import LogoTenant from "../../assets/LogoTenant";
import { useHomeNavigation } from "../../services/homeNavigation";
import { useFormatMessage } from "../../services/formatMessageHook";
import { useAppContext } from "../../services/appContext";
export var Header = function (props) {
    var navigateToHomePage = useHomeNavigation().navigateToHomePage;
    var formatMessage = useFormatMessage().formatMessage;
    var isLoggedIn = useAppContext().isLoggedIn;
    return (_jsxs(HeaderStyled, __assign({ "$isMainPage": isLoggedIn, style: props.style }, { children: [_jsxs(LogoStyled, __assign({ onClick: function () { return navigateToHomePage(); } }, { children: [_jsx(LogoTenant, { height: 24 }), _jsx("span", __assign({ style: { fontSize: "14px", color: "#262626", fontWeight: 400 } }, { children: formatMessage("dde.header.title") }))] })), props.children] })));
};
