var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useAppContext } from "../../../services/appContext";
import Alert from "antd/es/alert";
import { useFormatMessage } from "../../../services/formatMessageHook";
import styled from "styled-components";
import moment from "moment/moment";
import { dateFormat24, dateFormat24HU, dateFormat24HU_EN } from "../../../services/formatters";
import { useTenantContext } from "../../../services/tenantContext";
import { getLocaleFromLocalStorage } from "../../../services/appStorage";
var AlertWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 20px;\n"], ["\n  margin-bottom: 20px;\n"])));
var PortalBlock = function () {
    var _a = useAppContext(), portalBlockScheduled = _a.portalBlockScheduled, portalBlockScheduledDates = _a.portalBlockScheduledDates, portalBlocked = _a.portalBlocked, portalBlockedDate = _a.portalBlockedDate;
    var formatMessage = useFormatMessage().formatMessage;
    var tenant = useTenantContext().tenant;
    var getDateFormatter = function () {
        if (tenant === "HU") {
            if (getLocaleFromLocalStorage() === "en") {
                return dateFormat24HU_EN;
            }
            else {
                return dateFormat24HU;
            }
        }
        else {
            return dateFormat24;
        }
    };
    if (portalBlockScheduled) {
        return (_jsx(AlertWrapper, { children: _jsx(Alert, { description: formatMessage("dde.login.portal.block.scheduled.message", { "dateAndTimeTo": moment(portalBlockScheduledDates.dateAndTimeTo).format(getDateFormatter()), "dateAndTimeFrom": moment(portalBlockScheduledDates.dateAndTimeFrom).format(getDateFormatter()) }), message: formatMessage("dde.login.portal.block.scheduled.title"), type: "warning", showIcon: true, closable: true }) }));
    }
    if (portalBlocked) {
        return (_jsx(AlertWrapper, { children: _jsx(Alert, { description: formatMessage("dde.login.portal.block.started.message", { "dateAndTime": moment(portalBlockedDate.dateAndTimeTo).format(getDateFormatter()) }), message: formatMessage("dde.login.portal.block.started.title"), type: "error", showIcon: true }) }));
    }
    return null;
};
export default PortalBlock;
var templateObject_1;
