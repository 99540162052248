var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "styles/App.scss";
import InternetLoginRouterTenant from "./InternetLoginRouterTenant";
import IntranetLoginRouterTenant from "./IntranetLoginRouterTenant";
import GenericPortal from "./pages/common/GenericPortal/GeneralPortal";
import { useAppContext } from "./services/appContext";
import { TabLock } from "./pages/common/TabLock/TabLock";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useLocation } from "react-router-dom";
import { executeAxios } from "./services/axiosHook";
import { getFlowIdFromLocalStorage, setExternalParamInLocalStorage, userIsLoggedIn } from "./services/appStorage";
import { useSingleTab } from "./pages/common/TabLock/useSingleTab";
var newTabUuid = uuidv4();
var getWindowOpenConfig = {
    url: "/dde/usermanagement/getUserOpenedWindow",
    method: "GET",
};
var setWindowOpenConfig = {
    url: "/dde/usermanagement/noauth/setUserOpenedWindow",
    method: "GET",
};
function AppWrapper(props) {
    var _a = useAppContext(), isLoggedIn = _a.isLoggedIn, user = _a.user;
    var location = useLocation();
    var isSingleTab = useSingleTab();
    var _b = useState(false), otherWindowOpen = _b[0], setOtherWindowOpen = _b[1];
    var skipTabLockUris = ["/oauth/authorize"];
    function chackAndSetUserWindowOpen() {
        if (userIsLoggedIn()) {
            executeAxios({
                params: __assign({}, getWindowOpenConfig),
                onSuccess: function (response) {
                    var flowid = getFlowIdFromLocalStorage();
                    if (response && response !== flowid) {
                        setOtherWindowOpen(true);
                    }
                    else {
                        executeAxios({
                            params: __assign({}, setWindowOpenConfig),
                            onSuccess: function () {
                                setOtherWindowOpen(false);
                            },
                        });
                    }
                },
            });
        }
    }
    useEffect(function () {
        if (userIsLoggedIn()) {
            chackAndSetUserWindowOpen();
        }
    }, [isLoggedIn]);
    useEffect(function () {
        chackAndSetUserWindowOpen();
        console.log("location", location);
        if (location.search) {
            var params = new URLSearchParams(location.search);
            var ddeParam = params.get("ddeParam");
            if (ddeParam) {
                setExternalParamInLocalStorage(ddeParam);
            }
        }
        if (!skipTabLockUris.includes(location.pathname) && isSingleTab) {
            var intervalWindow_1 = window.setInterval(function () {
                chackAndSetUserWindowOpen();
            }, 60000);
            return function () {
                window.clearInterval(intervalWindow_1);
            };
        }
        return;
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(TabLock, { isVisible: (!skipTabLockUris.includes(location.pathname) && !isSingleTab) || otherWindowOpen }), _jsxs(GenericPortal, { children: [isLoggedIn && props.children, !isLoggedIn && props.isExternalUser && _jsx(InternetLoginRouterTenant, {}), !isLoggedIn && !props.isExternalUser && _jsx(IntranetLoginRouterTenant, {})] })] }));
}
export default AppWrapper;
