export var clientEsaw = ["ESAW_LOCAL", "ESAW_REMOTE", "ESAW_DISPOSABLE"];
export var dokuStates = ["ARCHIVED", "WAITING_ARCHIVAL", "WAITING_BANK_SIGNATURE", "WAITING_CLIENT_SIGNATURE", "WAITING_BANK_SIGNATURE_AUX"];
export var DokuboxSubmitReloadActionTypes = ["SendForArchiving", "SendToArchive"];
export var DokuBoxStaticAction;
(function (DokuBoxStaticAction) {
    DokuBoxStaticAction["Terminate"] = "Terminate";
    DokuBoxStaticAction["RejectTerminationRequest"] = "RejectTerminationRequest";
    DokuBoxStaticAction["ApproveTerminationRequest"] = "ApproveTerminationRequest";
    DokuBoxStaticAction["Reconfigure"] = "Reconfigure";
    DokuBoxStaticAction["AddOtherDoku"] = "AddOtherDoku";
    DokuBoxStaticAction["Submit"] = "Submit";
})(DokuBoxStaticAction || (DokuBoxStaticAction = {}));
