var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BlockIcon, Col, EditIcon, Row, showErrorMessage, ShowIcon, Tooltip } from "@g4p/react-styleguide-library";
import { PageTitle } from "../../../components/PageTitle/PageTitle";
import { TableActionButton } from "../../../components/TableActionButton/TableActionButton";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SegmentType, useAppContext } from "../../../services/appContext";
import { checkLoggedInUser } from "../../../services/appStorage";
import { executeAxios, executeAxiosPreventMultipleServerCalls } from "../../../services/axiosHook";
import { useFormatMessage } from "../../../services/formatMessageHook";
import { MainContainerStyled } from "../../../styles/styles";
import { usePermission } from "../../../utils/permissions";
import { getByLocale, getByLocaleBG, useCatalogues } from "../../../services/appApi";
import { useTenantContext } from "../../../services/tenantContext";
import { Sorter } from "../../../utils/tableUtils";
import ClientDokuBoxFilterPanel from "./components/ClientDokuBoxFilterPanel";
import DokuBoxListQuickView from "./components/DokuBoxListQuickView";
import { clientDokuBoxQuickView, filterClientDokuBoxConfig } from "./services/dokuBoxListApi";
import { DokuBoxStatus } from "./services/domain";
import Table from "../../../components/TranslationTable/Table";
import { formatDateWithMinutes } from "../../../services/formatters";
import { useUnlockDokuBox } from "../../../helper/unlockDokuBox";
var ClientDokuBoxList = function () {
    var _a = useFormatMessage(), formatMessage = _a.formatMessage, formatLabelFromSelectOptions = _a.formatLabelFromSelectOptions;
    var navigate = useNavigate();
    var hasAnyOfTheRoles = usePermission().hasAnyOfTheRoles;
    var _b = useState([]), clientDokuBoxList = _b[0], setClientDokuBoxList = _b[1];
    var _c = useState({}), filter = _c[0], setFilter = _c[1];
    var _d = useState({}), initialFilter = _d[0], setInitialFilter = _d[1];
    var _e = useState({}), quickViewItemsMap = _e[0], setQuickViewItemsMap = _e[1];
    var _f = useState(false), loading = _f[0], setloading = _f[1];
    var user = useAppContext().user;
    var tenant = useTenantContext().tenant;
    var _g = useUnlockDokuBox(), unlockAllDokuboxesLockedByCurrentUser = _g.unlockAllDokuboxesLockedByCurrentUser, unlockLoading = _g.unlockLoading;
    var getDokuBoxSearchStatus = useCatalogues().getDokuBoxSearchStatus;
    var _h = useAppContext(), language = _h.language, configurations = _h.configurations;
    var dataSource = clientDokuBoxList.map(function (client, index) {
        var listItem = client;
        listItem.key = client.id;
        return listItem;
    }) || [];
    var dokuboxStatusOptions = formatLabelFromSelectOptions(getDokuBoxSearchStatus(), "translationKey");
    useEffect(function () {
        unlockAllDokuboxesLockedByCurrentUser(function () { });
    }, []);
    var setQuickViewItems = function (list, id) {
        setQuickViewItemsMap(function (prevState) {
            prevState[id] = list.map(function (clientItem, index) {
                var listItem = clientItem;
                listItem.key = index;
                return listItem;
            });
            return prevState;
        });
    };
    var isEditEnabled = function (record) {
        if (tenant === "HU") {
            return record.status === DokuBoxStatus.IN_PROGRESS;
        }
        return record.editButtonAvailable && record.status === DokuBoxStatus.IN_PROGRESS;
    };
    var calculateColumns = function () {
        var translatedColumns = [
            {
                title: formatMessage("dde.clientDokuBoxList.table.dokuBoxName"),
                dataIndex: "dokuBoxName",
                key: "dokuBoxName",
                sorter: function (elem1, elem2) {
                    return Sorter.DEFAULT(getByLocale(elem1.dokuBoxNameEn, elem1.dokuBoxNameLocal), getByLocale(elem2.dokuBoxNameEn, elem2.dokuBoxNameLocal));
                },
                sortDirections: ["ascend", "descend"],
                render: function (text, record) { return _jsx("div", { children: getByLocale(record.dokuBoxNameEn, record.dokuBoxNameLocal) }); },
            },
            {
                title: formatMessage("dde.clientDokuBoxList.table.dokuBoxCustomName"),
                dataIndex: "dokuBoxCustomName",
                key: "dokuBoxCustomName",
                sorter: function (elem1, elem2) { return Sorter.DEFAULT(elem1.dokuBoxCustomName, elem2.dokuBoxCustomName); },
                sortDirections: ["ascend", "descend"],
            },
            {
                title: formatMessage("dde.clientDokuBoxList.table.status"),
                dataIndex: "status",
                key: "status",
                sorter: function (elem1, elem2) {
                    return Sorter.DEFAULT(formatMessage("dde.catalogue.dokuboxsearchstatus." + elem1.status.toLowerCase()), formatMessage("dde.catalogue.dokuboxsearchstatus." + elem2.status.toLowerCase()));
                },
                sortDirections: ["ascend", "descend"],
                render: function (text) {
                    return _jsx("span", { children: formatMessage("dde.catalogue.dokuboxsearchstatus." + text.toLowerCase()) });
                },
            },
            {
                title: formatMessage("dde.clientDokuBoxList.table.lastModification"),
                dataIndex: "lastModification",
                key: "lastModification",
                sorter: function (elem1, elem2) { return Sorter.DATE(elem1.lastModification, elem2.lastModification); },
                sortDirections: ["ascend", "descend"],
                render: function (value) {
                    return _jsx("span", { children: formatDateWithMinutes(value) });
                },
            },
            {
                title: formatMessage("dde.clientDokuBoxList.table.dokuBoxId"),
                dataIndex: "id",
                key: "id",
                sorter: function (elem1, elem2) { return Sorter.NUMBER(elem1.id, elem2.id); },
                sortDirections: ["ascend", "descend"],
                render: function (text, record) {
                    return (_jsxs("div", __assign({ style: { display: "flex" } }, { children: [_jsx("span", __assign({ style: { marginRight: "5px" } }, { children: text })), _jsx(TableActionButton, { type: "copy", onClick: function () {
                                    navigator.clipboard.writeText(record.id);
                                } })] })));
                },
            },
            {
                title: "",
                dataIndex: "actions",
                key: "actions",
                render: function (text, record) {
                    return (_jsxs("div", __assign({ style: { whiteSpace: "nowrap" } }, { children: [_jsx(Tooltip, __assign({ title: formatMessage("dde.bankerDokuBoxList.tooltip.viewItem") }, { children: _jsx(ShowIcon, { style: { cursor: "pointer", marginRight: "20px" }, onClick: function () { return navigate("/app/viewdokubox/" + record.id); } }) })), isEditEnabled(record) && record.lockedByUser && !checkLoggedInUser(record.lockedByUser) ? (_jsx(Tooltip, __assign({ title: formatMessage("dde.clientDokuBoxList.tooltip.lockedByUser") }, { children: _jsx(BlockIcon, { style: { cursor: "pointer" }, onClick: function () { return navigate("/app/viewdokubox/" + record.id); } }) }))) : (_jsxs(_Fragment, { children: [isEditEnabled(record) && (_jsx(Tooltip, __assign({ title: formatMessage("dde.bankerDokuBoxList.tooltip.editItem") }, { children: _jsx(EditIcon, { style: { cursor: "pointer" }, onClick: function () { return navigate("/app/editdokubox/" + record.id); } }) }))), !isEditEnabled(record) && _jsx(EditIcon, { style: { cursor: "not-allowed", opacity: "0.4" } })] }))] })));
                },
            },
        ];
        return translatedColumns;
    };
    var _j = useState(calculateColumns()), columns = _j[0], setColumns = _j[1];
    useEffect(function () {
        setColumns(calculateColumns());
    }, [language]);
    var quickViewColumns = [
        {
            title: formatMessage("dde.clientDokuBoxList.quickViewTable.dokuName"),
            dataIndex: "dokuName",
            key: "dokuName",
            render: function (text, record) { return _jsx("div", { children: getByLocale(record.dokuNameEn, record.dokuNameLocal) }); },
        },
        {
            title: formatMessage("dde.clientDokuBoxList.quickViewTable.status"),
            dataIndex: "status",
            key: "status",
            render: function (text) {
                return _jsx("span", { children: formatMessage("dde.catalogue.dokustatus." + text.toLowerCase()) });
            },
        },
        {
            title: formatMessage("dde.clientDokuBoxList.quickViewTable.currentUser"),
            dataIndex: "currentUser",
            key: "currentUser",
            render: function (text, record) {
                return (_jsx("span", __assign({ style: { textTransform: "none" } }, { children: getByLocaleBG(tenant, record.currentUser.join(", "), record.currentUserLocal.join(", ")) })));
            },
        },
        {
            title: formatMessage("dde.clientDokuBoxList.quickViewTable.uploadedBy"),
            dataIndex: "uploadedBy",
            key: "uploadedBy",
            render: function (text, record) {
                return _jsx("span", __assign({ style: { textTransform: "none" } }, { children: formatMessage(record.uploadedBy) }));
            },
        },
        {
            title: formatMessage("dde.clientDokuBoxList.quickViewTable.lastModification"),
            dataIndex: "lastModification",
            key: "lastModification",
            render: function (value) {
                return _jsx("span", { children: formatDateWithMinutes(value) });
            },
        },
    ];
    var createNewDokubox = function () {
        if (user && (user.segment === SegmentType.MB || user.segment === SegmentType.CORP)) {
            navigate("/app/flow/createdokubox/new");
        }
        else {
            showErrorMessage({
                title: formatMessage("dde.error.errorTitle"),
                message: formatMessage("dokubox.create.error.nosegmenttype"),
            });
        }
    };
    var handleSearch = function (filterValues) {
        setFilter(filterValues);
        fetchRecords(filterValues);
    };
    var fetchRecords = function (filterValues) {
        executeAxiosPreventMultipleServerCalls({
            params: __assign(__assign({}, filterClientDokuBoxConfig), { data: filterValues }),
            onFail: function (resp) {
                var _a;
                return showErrorMessage({
                    title: formatMessage("dde.error.errorTitle"),
                    message: formatMessage(((_a = resp === null || resp === void 0 ? void 0 : resp.data) === null || _a === void 0 ? void 0 : _a.messageKey) || "dde.error.generalError"),
                });
            },
            onLoading: setloading,
            onSuccess: function (resp) {
                setClientDokuBoxList(resp);
                setFilter(filterValues);
            },
        });
    };
    var fetchQuickViewRecords = function (id) {
        var requestApiConfig = __assign({}, clientDokuBoxQuickView);
        requestApiConfig.url = requestApiConfig.url + id;
        executeAxios({
            params: __assign({}, requestApiConfig),
            onFail: function (resp) {
                showErrorMessage({
                    title: formatMessage("dde.error.errorTitle"),
                    message: formatMessage("dde.clientDokuBoxList.error.QuickViewError"),
                });
                console.error("Quick view error: " + resp.error);
                setQuickViewItems([], id);
            },
            onLoading: setloading,
            onSuccess: function (resp) {
                setQuickViewItems(resp.items, id);
            },
        });
    };
    var handleTableChange = function (pagination, filters, sorter) {
        //TODO sorting
        console.log("TODO Sorter: ", sorter);
    };
    var sortData = function (data) {
        return data
            .slice()
            .sort(function (item1, item2) {
            return Sorter.DATE(new Date(item2.lastModification), new Date(item1.lastModification));
        });
    };
    var getInitialFilter = function () {
        if (tenant === "HR" || tenant === "RO") {
            initialFilter.status = DokuBoxStatus.IN_PROGRESS;
            initialFilter.statusSelect = dokuboxStatusOptions.find(function (x) { return x.value === DokuBoxStatus.IN_PROGRESS; });
        }
        return initialFilter;
    };
    var isCreateButtonVisible = function () {
        if (tenant === "CZ" || tenant === "SK") {
            return !configurations['ddeHideCreateDokuBoxButton_CZSK'];
        }
        else {
            return true;
        }
    };
    useEffect(function () {
        fetchRecords(getInitialFilter());
    }, [initialFilter, tenant]);
    return (_jsxs(MainContainerStyled, { children: [hasAnyOfTheRoles(["DOKUBOX_CREATE"]) && (_jsx(PageTitle, { title: formatMessage("dde.banker.dashboard.tab.dokuBox.management"), cta: hasAnyOfTheRoles(["DOKUBOX_CREATE"]) && isCreateButtonVisible()
                    ? {
                        label: formatMessage("dde.clientDokuBoxList.createBtn"),
                        onClick: createNewDokubox,
                    }
                    : undefined })), _jsx(Row, __assign({ maxWidth: "100%" }, { children: _jsx(Col, __assign({ ds: "100%" }, { children: _jsxs(Row, __assign({ maxWidth: "100%" }, { children: [_jsx(Col, __assign({ ds: "100%" }, { children: _jsx(ClientDokuBoxFilterPanel, { filter: getInitialFilter(), onSearchClick: function (filterValues) {
                                        handleSearch(filterValues);
                                    }, dokuboxStatusOptions: dokuboxStatusOptions }) })), _jsxs(Col, __assign({ ds: "100%" }, { children: [_jsx("br", {}), _jsx("br", {}), _jsx(Table, { loading: loading || unlockLoading, dataSource: sortData(dataSource), columns: columns, onChange: handleTableChange, expandable: {
                                            expandedRowRender: function (record) { return (_jsx(DokuBoxListQuickView, { label: tenant === "BG" ? "" : record.technicalId, columns: quickViewColumns, dataSource: quickViewItemsMap[record.id] })); },
                                            rowExpandable: function (record) { return true; },
                                            onExpand: function (expanded, record) {
                                                if (expanded) {
                                                    fetchQuickViewRecords(record.id);
                                                }
                                            },
                                        } })] }))] })) })) }))] }));
};
export default ClientDokuBoxList;
