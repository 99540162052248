var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Radio } from "@g4p/react-styleguide-library";
import styled, { css } from "styled-components";
export var container = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tmax-width: 87.5vw;\n\tpadding: 0 40px;\n\tmargin: 0 auto;\n"], ["\n\tmax-width: 87.5vw;\n\tpadding: 0 40px;\n\tmargin: 0 auto;\n"])));
export var headerContainer = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    max-width: 100vw;\n    padding: 0 20px;\n    margin: 0 auto;\n\t\t\n\t\tborder-bottom: 1px solid #E5E5E5;\n"], ["\n    max-width: 100vw;\n    padding: 0 20px;\n    margin: 0 auto;\n\t\t\n\t\tborder-bottom: 1px solid #E5E5E5;\n"])));
export var RadioGroupStyled = styled(Radio.Group)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\tdisplay: flex;\t\n"], ["\n\tdisplay: flex;\t\n"])));
export var MainContainerStyled = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\t", ";\n\t", " {\n\t\t.uds__radio__container.uds__radio-lightTheme {\n\t\t\tmargin: 0;\n\t\t}\n\t}\n"], ["\n\t", ";\n\t", " {\n\t\t.uds__radio__container.uds__radio-lightTheme {\n\t\t\tmargin: 0;\n\t\t}\n\t}\n"])), container, RadioGroupStyled);
export var MainWrapperStyled = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\t.tabs-wrapper {\n\t\t> div {\n\t\t\tmax-width: initial;\n\t\t\tpadding: 0;\n\n\t\t\t.ant-tabs-nav-wrap {\n\t\t\t\tjustify-content: center;\n\t\t\t\t", ";\n\t\t\t}\n\n\t\t\t.ant-tabs-top > .ant-tabs-nav::before,\n\t\t\t.ant-tabs-bottom > .ant-tabs-nav::before,\n\t\t\t.ant-tabs-top > div > .ant-tabs-nav::before,\n\t\t\t.ant-tabs-bottom > div > .ant-tabs-nav::before {\n\t\t\t\tborder-bottom: 0;\n\t\t\t}\n\n\t\t\t.ant-tabs-nav .ant-tabs-nav-operations button {\n\t\t\t\tborder-bottom: 0;\n\t\t\t}\n\n\t\t\t.ant-tabs-tab {\n\t\t\t\tmin-width: initial;\n\t\t\t\tpadding-bottom: 5px;\n\t\t\t\tborder-bottom: 0;\n\t\t\t\tfont-size: 15px;\n\t\t\t}\n\t\t}\n\t}\n\n\t.tabs-wrapper .tabs-wrapper > div {\n\t\tborder-top: 1px solid #ccc;\n\t}\n"], ["\n\t.tabs-wrapper {\n\t\t> div {\n\t\t\tmax-width: initial;\n\t\t\tpadding: 0;\n\n\t\t\t.ant-tabs-nav-wrap {\n\t\t\t\tjustify-content: center;\n\t\t\t\t", ";\n\t\t\t}\n\n\t\t\t.ant-tabs-top > .ant-tabs-nav::before,\n\t\t\t.ant-tabs-bottom > .ant-tabs-nav::before,\n\t\t\t.ant-tabs-top > div > .ant-tabs-nav::before,\n\t\t\t.ant-tabs-bottom > div > .ant-tabs-nav::before {\n\t\t\t\tborder-bottom: 0;\n\t\t\t}\n\n\t\t\t.ant-tabs-nav .ant-tabs-nav-operations button {\n\t\t\t\tborder-bottom: 0;\n\t\t\t}\n\n\t\t\t.ant-tabs-tab {\n\t\t\t\tmin-width: initial;\n\t\t\t\tpadding-bottom: 5px;\n\t\t\t\tborder-bottom: 0;\n\t\t\t\tfont-size: 15px;\n\t\t\t}\n\t\t}\n\t}\n\n\t.tabs-wrapper .tabs-wrapper > div {\n\t\tborder-top: 1px solid #ccc;\n\t}\n"])), container);
export var HeaderContainerStyled = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n\t", ";\n"], ["\n\t", ";\n"])), headerContainer);
export var ModalFooterStyled = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n\tdisplay: flex;\n\talign-items: center;\n\tmargin-top: 16px;\n\t> div {\n\t\t&:nth-child(2) {\n\t\t\tflex: 1;\n\t\t\ttext-align: right;\n\n\t\t\tbutton {\n\t\t\t\tmargin-left: 10px;\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tdisplay: flex;\n\talign-items: center;\n\tmargin-top: 16px;\n\t> div {\n\t\t&:nth-child(2) {\n\t\t\tflex: 1;\n\t\t\ttext-align: right;\n\n\t\t\tbutton {\n\t\t\t\tmargin-left: 10px;\n\t\t\t}\n\t\t}\n\t}\n"])));
export var AutoCompleteStyled = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  position: relative;\n  display: inline-block;\n\twidth: 100%;\n  input{\n    padding-right: 40px !important;\n  }\n  svg{\n    position: absolute;\n    right: 10px;\n    top: 5px;\n    width: 24px;\n  }\n"], ["\n  position: relative;\n  display: inline-block;\n\twidth: 100%;\n  input{\n    padding-right: 40px !important;\n  }\n  svg{\n    position: absolute;\n    right: 10px;\n    top: 5px;\n    width: 24px;\n  }\n"])));
export var CustomRowStyled = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n\tdisplay: flex;\n\tflex-direction: row;\n\tjustify-content: flex-end;\n\talign-content: baseline;\n"], ["\n\tdisplay: flex;\n\tflex-direction: row;\n\tjustify-content: flex-end;\n\talign-content: baseline;\n"])));
export var QuickActionsPopupPlaceholderStyled = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n\tflex-basis: auto;\n"], ["\n\tflex-basis: auto;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
