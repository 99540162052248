import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { removeWindowOpen } from "../../../services/appApi";
var STORAGE_KEY = "activeTabs";
var HEARTBEAT_INTERVAL = 10000;
var STALE_TIME = HEARTBEAT_INTERVAL * 3;
export var useSingleTab = function () {
    var _a = useState(true), isSingleTab = _a[0], setIsSingleTab = _a[1];
    var tabId = sessionStorage.getItem("tabId") || uuidv4();
    useEffect(function () {
        sessionStorage.setItem("tabId", tabId);
        var getActiveTabs = function () {
            return JSON.parse(localStorage.getItem(STORAGE_KEY) || "[]");
        };
        var updateTabHeartbeat = function () {
            var currentTime = Date.now();
            var tabs = getActiveTabs();
            var tabIndex = tabs.findIndex(function (tab) { return tab.tabId === tabId; });
            if (tabIndex >= 0) {
                tabs[tabIndex].timestamp = currentTime;
            }
            else {
                tabs.push({ tabId: tabId, timestamp: currentTime });
            }
            localStorage.setItem(STORAGE_KEY, JSON.stringify(tabs));
        };
        var removeStaleTabs = function () {
            var currentTime = Date.now();
            var tabs = getActiveTabs();
            tabs = tabs.filter(function (tab) { return currentTime - tab.timestamp <= STALE_TIME; });
            localStorage.setItem(STORAGE_KEY, JSON.stringify(tabs));
        };
        var checkForMultipleTabs = function () {
            removeStaleTabs();
            var activeTabs = getActiveTabs();
            setIsSingleTab(activeTabs.length <= 1);
        };
        var handleBeforeUnload = function () {
            var tabs = getActiveTabs();
            removeWindowOpen();
            tabs = tabs.filter(function (tab) { return tab.tabId !== tabId; });
            localStorage.setItem(STORAGE_KEY, JSON.stringify(tabs));
        };
        var handleVisibilityChange = function () {
            if (document.visibilityState === "visible") {
                checkForMultipleTabs();
            }
        };
        updateTabHeartbeat();
        checkForMultipleTabs();
        var heartbeatInterval = setInterval(function () {
            updateTabHeartbeat();
            checkForMultipleTabs();
        }, HEARTBEAT_INTERVAL);
        window.addEventListener("beforeunload", handleBeforeUnload);
        document.addEventListener("visibilitychange", handleVisibilityChange);
        return function () {
            clearInterval(heartbeatInterval);
            window.removeEventListener("beforeunload", handleBeforeUnload);
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [tabId]);
    return isSingleTab;
};
