var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FacebookFilled, LinkedinFilled, TwitterSquareFilled } from "@ant-design/icons";
import { Button, CookiesIcon, Typography } from "@g4p/react-styleguide-library";
import moment from "moment";
import { useTenantContext } from "../../services/tenantContext";
import styled from "styled-components";
import { MainContainerStyled } from "../../styles/styles";
import LogoMonochromaticTenant from "../../assets/LogoMonochromaticTenant";
import { useFormatMessage } from "../../services/formatMessageHook";
import roImage from "../../assets/romanias_best_bank_for_corporates.png";
var PageFooterWrapperStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tbackground-color: #262626;\n\theight: 100px;\n\twidth: 100%;\n\tmargin-top: 40px;\n\n\t.left,\n\t.right {\n\t\tdisplay: flex;\n\t\tflex: 1;\n\t}\n\n\t.left {\n\t\tflex-direction: column;\n\t}\n\n\t.right {\n\t\tjustify-content: flex-end;\n\n\t\t.ant-btn {\n\t\t\tcolor: white;\n\t\t}\n\t}\n"], ["\n\tbackground-color: #262626;\n\theight: 100px;\n\twidth: 100%;\n\tmargin-top: 40px;\n\n\t.left,\n\t.right {\n\t\tdisplay: flex;\n\t\tflex: 1;\n\t}\n\n\t.left {\n\t\tflex-direction: column;\n\t}\n\n\t.right {\n\t\tjustify-content: flex-end;\n\n\t\t.ant-btn {\n\t\t\tcolor: white;\n\t\t}\n\t}\n"])));
var ROFooterImage = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    background-image: url(", ");\n    background-size: contain;\n\t\tbackground-repeat: no-repeat;\n\t\tbackground-color: #e5e5e5;\n    background-position: center;\n\t\tbackground-origin: content-box;\n    text-align: center;\n\t\twidth: 110px;\n\t\tborder-radius: 0.5rem;\n\t\tmargin-left: 20px;\n\t\tpadding: 3px;\n"], ["\n    background-image: url(", ");\n    background-size: contain;\n\t\tbackground-repeat: no-repeat;\n\t\tbackground-color: #e5e5e5;\n    background-position: center;\n\t\tbackground-origin: content-box;\n    text-align: center;\n\t\twidth: 110px;\n\t\tborder-radius: 0.5rem;\n\t\tmargin-left: 20px;\n\t\tpadding: 3px;\n"])), roImage);
var PageFooter = function (props) {
    var _a, _b;
    var tenant = useTenantContext().tenant;
    var formatMessage = useFormatMessage().formatMessage;
    var socialMediaLinks = {
        HU: {
            linkedin: "https://hu.linkedin.com/company/unicredit-bank-hungary",
            facebook: "https://m.facebook.com/profile.php?id=426959450756361",
        },
        CZ: {
            linkedin: "https://cz.linkedin.com/company/unicredit-bank",
            facebook: "https://m.facebook.com/profile.php?id=108155395885578",
        },
        SK: {
            linkedin: "https://sk.linkedin.com/company/unicredit-bank",
            facebook: "https://m.facebook.com/profile.php?id=344673685664506",
        },
        RS: {
            linkedin: "https://rs.linkedin.com/company/unicredit-bank-serbia",
            facebook: "https://www.facebook.com/UniCreditBankSrbija/",
        },
        BG: {
            linkedin: "https://www.linkedin.com/company/unicredit-bulbank/",
            facebook: "https://www.facebook.com/unicreditbulbank",
        },
        HR: {
            linkedin: "https://www.linkedin.com/company/zagrebacka-banka",
            facebook: "https://www.facebook.com/UniCreditEurope",
        },
        RO: {
            linkedin: "https://www.linkedin.com/company/unicredit-romania/",
            facebook: "https://www.facebook.com/UniCreditEurope",
        },
    };
    var linkedinHref = (_a = socialMediaLinks[tenant]) === null || _a === void 0 ? void 0 : _a.linkedin;
    var facebookHref = (_b = socialMediaLinks[tenant]) === null || _b === void 0 ? void 0 : _b.facebook;
    return (_jsx(PageFooterWrapperStyled, { children: _jsxs(MainContainerStyled, __assign({ style: { display: "flex", alignItems: "center", justifyContent: "center", height: "100%" } }, { children: [_jsxs("div", __assign({ style: { display: "flex" } }, { children: [_jsxs("div", __assign({ className: "left" }, { children: [_jsx(LogoMonochromaticTenant, { height: 20, color: "#fff" }), _jsxs(Typography.Text, __assign({ style: { color: "white", marginTop: "5px" } }, { children: ["\u00A9\u00A0", moment().year(), " Unicredit - All rights reserved."] })), props.children] })), tenant === "RO" && _jsx(ROFooterImage, {})] })), _jsxs("div", __assign({ className: "right" }, { children: [props.cookiesButtonDisplayed && (_jsxs(Button, __assign({ type: "text", onClick: function (event) {
                                if (props.navigateToCookieDetailsPage !== undefined) {
                                    props.navigateToCookieDetailsPage();
                                }
                            } }, { children: [_jsx(CookiesIcon, { style: { fill: "white", width: 25, height: 25 } }), _jsx("span", __assign({ style: {
                                        fontSize: 14,
                                        color: "white",
                                        marginTop: "auto",
                                        marginLeft: 5,
                                        marginRight: 5,
                                        fontWeight: "normal",
                                        textTransform: "capitalize",
                                    } }, { children: formatMessage("dde.header.CookiesInformation") }))] }))), tenant !== "HU" &&
                            _jsxs(Button, __assign({ type: "text", href: "https://twitter.com/unicredit_pr", target: "_blank" }, { children: [_jsx(TwitterSquareFilled, { style: { fontSize: "2em", color: "white" } }), "Twitter"] })), linkedinHref && (_jsxs(Button, __assign({ type: "text", href: linkedinHref, target: "_blank" }, { children: [_jsx(LinkedinFilled, { style: { fontSize: "2em", color: "white" } }), "LinkedIn"] }))), tenant !== "HU" && facebookHref && (_jsxs(Button, __assign({ type: "text", href: facebookHref, target: "_blank" }, { children: [_jsx(FacebookFilled, { style: { fontSize: "2em", color: "white" } }), "Facebook"] })))] }))] })) }));
};
export default PageFooter;
var templateObject_1, templateObject_2;
